import React from "react"
import {FeatureProductHomeStyled, FeatureListStyle} from './featureProductHomeStyled'
import FeatureProduct from './featureProduct'
import PhysicalIcon from './icons/physicalIcon'




const FeatureProductHome = () => {
    return (
        <FeatureProductHomeStyled>
            <h3 className="head">Product Standard</h3>
            <FeatureListStyle>
                <PhysicalIcon name="graphic-1"/>
                <FeatureProduct />
            </FeatureListStyle>
        </FeatureProductHomeStyled>
    )
}

 export default FeatureProductHome

