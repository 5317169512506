import React from "react"


const LogoAnimate = ({name}) => {
    return (
        <svg version="1.1" id="logo_animate" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
	 		width="803px" height="298px" viewBox="0 0 803 298" enableBackground="new 0 0 803 298" className={name}>
		<g className="savie">
			
				<path d="M75.389,139.725c-5.504,0-10.018,1.462-13.539,4.384c-3.526,2.926-5.287,7.137-5.287,12.637
					c0,5.504,1.632,9.929,4.9,13.281c3.264,3.353,7.52,5.932,12.766,7.737c5.242,1.805,11.089,3.525,17.537,5.157
					c6.447,1.636,12.85,3.61,19.213,5.932c6.358,2.321,12.161,5.202,17.408,8.64c5.243,3.441,9.498,8.297,12.766,14.57
					c3.264,6.278,4.9,13.798,4.9,22.566c0,15.304-6.234,28.412-18.697,39.328c-12.467,10.921-28.884,16.377-49.257,16.377
					s-36.794-4.9-49.257-14.7c-12.468-9.8-18.697-23.727-18.697-41.779h46.937c1.201,14.442,8.595,21.664,22.179,21.664
					c6.358,0,11.347-1.677,14.958-5.029c3.611-3.353,5.416-7.607,5.416-12.766s-1.636-9.369-4.9-12.637
					c-3.268-3.265-7.523-5.843-12.766-7.737c-5.246-1.89-11.089-3.65-17.537-5.287c-6.447-1.632-12.854-3.65-19.213-6.06
					c-6.363-2.406-12.165-5.287-17.408-8.64c-5.247-3.353-9.501-8.164-12.766-14.442c-3.268-6.273-4.9-13.708-4.9-22.308
					c0-16.848,6.274-30.343,18.826-40.489c12.548-10.143,28.582-15.216,48.097-15.216c19.511,0,35.287,4.429,47.323,13.282
					c12.032,8.856,18.311,22.823,18.826,41.907H95.247c-0.689-6.532-2.796-11.561-6.318-15.087
					C85.402,141.489,80.889,139.725,75.389,139.725z"/>
			
			
				<path d="M295.884,288.528l-10.058-30.432h-64.989l-10.058,30.432h-46.937l62.926-181.557h53.642l62.668,181.557
					H295.884z M231.927,224.57h42.81l-21.405-64.473L231.927,224.57z"/>
			
			
				<path d="M391.419,288.528l-64.215-181.041h46.937l44.873,137.973l44.873-137.973h46.938l-64.218,181.041H391.419z"
					/>
			
			
				<path d="M531.969,288.528V107.487h44.1v181.041H531.969z"/>
			
			
				<path d="M724.357,106.972v33.526h-65.506v38.941h57.768v33.526h-57.768v42.036h65.506v33.526H614.752V106.972
					H724.357z"/>
			
		</g>
		<g className="symbol">
			<path d="M748.209,8.041c0,0-7.557,12.729-19.922,17.502c-4.726,1.831-10.661,2.324-15.736,2.324
				c-6.158,0-11.059-0.732-11.059-0.732s-0.349,67.623,46.701,80.352c47.065-12.729,46.73-80.352,46.73-80.352
				s-4.9,0.732-11.057,0.732c-5.076,0-10.996-0.493-15.736-2.324C755.768,20.771,748.209,8.041,748.209,8.041L748.209,8.041z
				M712.583,34.248c7.032,0,12.903-0.891,17.472-2.657c8.083-3.119,14.304-9.117,18.171-13.731
				c3.866,4.614,10.087,10.612,18.17,13.731c4.567,1.766,10.439,2.657,17.472,2.657c1.878,0,3.642-0.063,5.202-0.159
				c-0.525,6.332-1.847,16.277-5.124,26.619c-6.873,21.672-18.887,35.197-35.72,40.241c-16.835-5.044-28.849-18.569-35.724-40.241
				c-3.275-10.342-4.598-20.287-5.121-26.619C708.94,34.184,710.705,34.248,712.583,34.248L712.583,34.248z"/>


			<polygon points="756.037,50.302 756.037,36.284 740.413,36.284 740.413,50.302 723.93,50.302 723.93,68.411 
				740.413,68.411 740.413,82.427 756.037,82.427 756.037,68.411 772.521,68.411 772.521,50.302 	"/>
		</g>
		</svg>
    )
}

export default LogoAnimate
