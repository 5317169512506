import styled from 'styled-components'
import * as palette from '../styles/variables'
import { device, width } from '../styles/device'
import {FeatureStyled} from './featureStyled'
import { lighten, darken } from 'polished'



export const FeatureProductHomeStyled = styled.div`
    width: 100%;
    margin: 0 auto;
    background-color: ${palette.lightBg};
    padding: 80px 15px 80px 15px;
    position: relative;
    text-align: center;

    @media ${device.mobile} {
        padding: 0;
    }

    .head {
        text-align: center;
        color: ${palette.cta};
        font-weight: 700;
        font-size: ${palette.fontSizeMdl};
        margin-bottom: 2rem;
        text-transform: uppercase;
        max-width: 100%;
        display: inline-block;
        line-height: 1.6rem;

        @media ${device.mobile} {
            display: none;

        }
    }

    .graphic-1 {
        width: 700px;
        position: absolute;
        top: 80px;
        height: 500px;
        opacity: .1;
        right: 0;

        @media ${device.mobile} {
            opacity: .1;
            display: block;

        }
    }

`;

export const FeatureListStyle = styled(FeatureStyled)`
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    max-width: ${width.xxl};
    margin: 0 auto;
    position: relative;

    .featureCard {
        width: 100%;
        flex-direction: column;
        align-items: center;
        align-self: stretch;
        padding: 0;
        background-color: ${palette.cta};
        padding-top: 3rem;
        padding-bottom: 3rem;
        cursor: pointer;

        @media ${device.mobile} {
            width: 50%;
        }

        @media ${device.laptop} {
            width: 33.33%;
        }

        .icon {
            width: 70px;
            height: 70px;
            margin-bottom: 1rem;

            path {
                fill: ${palette.white};
            }

            rect {
                fill: ${palette.white};
            }
        }
    }

    @media ${device.mobile} {
        .card-2 {
            background-color: ${lighten(0.05, palette.cta)};
        }
        .card-3 {
            background-color: ${lighten(0.1, palette.cta)};
        }
        .card-4 {
            background-color: ${lighten(0.03, palette.cta)};
        }
        .card-5 {
            background-color: ${darken(0.04, palette.cta)};
        }
    }

`;
