import styled from 'styled-components'
import * as palette from '../styles/variables';
import { device, width } from '../styles/device';
import {TitleWithTextStyled} from './titleWithTextStyled'



export const TextWithImageSectionStyled = styled.div`
    display: flex;
    width: 100%;
    background-color: ${palette.lightBg};



`;

export const TextWithImageInnerStyle = styled(TitleWithTextStyled)`
        max-width: ${width.xxl};
        margin: 0 auto;
        display: flex;
        flex-direction: column;

        @media ${device.desktop} {
            flex-direction: row;
        }

        .textWithImageSection {
            flex: 1 1;
        }

        .boxWrapper {

            .container {
                padding-left: 0;
                padding-right: 0;

                @media ${device.desktop} {
                    padding-left: 30px;
                    padding-right: 30px;
                }

                .title, p {
                    text-align: left;
                }
            }
        }

    .imagesWrapper {
        background-color: ${palette.white};
        flex: 1 1;

        .innerWrap {
            display: flex;
            flex-wrap: wrap;
        }

        .gatsby-image-wrapper {
            width: 50%;
            max-height: unset;


            img {
                border: 1px solid white;

            }

        }
    }


`;