import React from "react"
import { useStaticQuery, graphql } from "gatsby"



const Certificate = () => {
    const data = useStaticQuery(graphql`
    query ClientsQuery {
        allContentfulClients {
            edges {
              node {
                id
                image {
                  file {
                    url
                  }
                  sizes(resizingBehavior: SCALE) {
                    ...GatsbyContentfulSizes_withWebp
                   }
                }
                title
              }
            }
          }
        }
    `)
    if(!data.allContentfulClients.edges) {
        return <div>Loading...</div>
    } else {
        return data.allContentfulClients.edges.map((edge, idx) => {
            if(edge.node && edge.node.image && edge.node.image.file) {
                return (
                    <div key={edge.node.id} className="imageWrap" data-index={idx}>
                        <img src={edge.node.image.file.url} className="certificate" alt={edge.node.title} />
                    </div>
                )
            }
            return true;
        })
    }

}
 export default Certificate

