import React from 'react'
import {TextBoxStyled, TitleWithTextInBoxStyled} from './textBoxStyled'
import TitleWithText from './titleWithText'
import PropTypes from 'prop-types'




const TextBox = ({className}) => {
    return <TextBoxStyled className={className}>
                <TitleWithTextInBoxStyled className="boxWrapper">
                    <TitleWithText slug="whether" isLightText={true}/>
                </TitleWithTextInBoxStyled>
        </TextBoxStyled>

}

TextBox.propTypes = {
    className: PropTypes.string
}
export default TextBox