import styled from 'styled-components'
import * as palette from '../styles/variables';
import { device, width } from '../styles/device';
import {TitleWithTextStyled} from './titleWithTextStyled'



export const TextBoxStyled = styled.div`
    background-color: white;
    background-image: ${palette.gradient};
    padding: 80px 20px 80px 20px;
`;

export const TitleWithTextInBoxStyled = styled(TitleWithTextStyled)`
    max-width: ${width.xxl};
    margin: 0 auto;




    .container {
        text-align: left;

        @media ${device.tablet} {
            text-align: center;
        }

        .title {
            color: ${palette.white};
            text-align: left;
            text-transform: unset;

            @media ${device.tablet} {
                text-align: center;
            }
        }

        p {
            color: ${palette.white};
        }
    }



`;
