import styled from 'styled-components'
import * as palette from '../styles/variables'
import { device, width } from '../styles/device'


export const CertificationsStyled = styled.div`
    background-color: ${palette.white};

     .container {
        max-width: ${width.xxxl};
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        padding-top: 80px;
        padding-right: 15px;
        padding-bottom: 40px;
        padding-left: 15px;
    }

    .title {
        text-align: center;
        margin-bottom: 0;
        font-weight: 600;
        line-height: 1.6rem;
        color: ${palette.cta};
        font-family: ${palette.fontFamilyMain};
        font-size: ${palette.fontSizeMdl};
        text-transform: uppercase;
        /* border: 1px solid ${palette.cta}; */
        max-width: 100%;
        display: inline-block;
        margin-bottom: 1rem;
    }

    .clientsWrap {
        display: flex;
        overflow: hidden;
        width: 80%;
    }

    .imageWrap {
        width: 100%;
        padding: 1rem;
        align-self: center;
        min-width: 50%;
        transition: all 0.4s ease-in-out;

        .certificate {
            max-width: 100px;
            margin: 0 auto;
            filter: opacity(60%);
        }

        @media ${device.mobile} {
            min-width: 25%;
        }

    }

`;

