import React from "react"


const GlovesIcon = ({name}) => {
    return (
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
	 viewBox="0 0 100 100" className={name}>
        <g>
            <g>
                <path fill="#FFFFFF" d="M89.81,31.66c-0.37-1.55-1.26-2.85-2.51-3.66c-0.71-0.46-1.49-0.73-2.29-0.79
                    c0.39-1.17,0.45-2.43,0.15-3.66c-0.37-1.55-1.26-2.85-2.51-3.66c-0.69-0.44-1.44-0.71-2.21-0.79l0.03-0.06
                    c0.72-1.39,0.91-3.02,0.54-4.57c-0.37-1.55-1.26-2.85-2.51-3.66c-1.25-0.8-2.7-1.02-4.09-0.6c-1.39,0.42-2.55,1.41-3.27,2.81
                    l-0.04,0.07c-0.44-0.7-1.01-1.3-1.71-1.75c-2.57-1.66-5.88-0.67-7.36,2.21l-7.39,14.3c-0.98-1.51-2.57-2.5-4.37-2.5
                    c-0.84,0-1.63,0.21-2.34,0.6c-0.43-2.85-2.65-5.02-5.32-5.02c-0.81,0-1.58,0.2-2.27,0.56V21.4c0-3.32-2.42-6.03-5.39-6.03
                    s-5.39,2.7-5.39,6.03v0.08c-0.69-0.36-1.46-0.56-2.27-0.56c-2.97,0-5.39,2.7-5.39,6.03v24.11l-1.76-2.1
                    c-2.2-2.62-5.91-2.77-8.27-0.33c-2.13,2.2-2.5,5.86-0.86,8.53c3.14,5.12,6.72,9.93,10.65,14.32l0.24,0.27l0,16.51
                    c0,0.46,0.16,0.91,0.46,1.24c0.29,0.33,0.69,0.51,1.1,0.51h26.8c0.86,0,1.56-0.78,1.56-1.75v-8.48L58.38,84
                    c0.25,0.16,0.51,0.23,0.78,0.23c0.54,0,1.07-0.31,1.35-0.87l6.06-11.72c3-2.42,5.72-5.26,8.07-8.44c0.55-0.74,0.46-1.84-0.21-2.46
                    c-0.66-0.62-1.65-0.51-2.2,0.23c-2.26,3.05-4.87,5.75-7.75,8.04c-0.18,0.15-0.34,0.33-0.45,0.55L58.59,80.1l-6.76-4.36v-1.02
                    c2.54-6.31,3.83-13.02,3.83-19.95V32.84l9.07-17.56c0.62-1.21,2.01-1.63,3.1-0.93c1.08,0.7,1.45,2.24,0.84,3.45L58.55,37.38
                    c-0.43,0.84-0.18,1.9,0.57,2.39c0.75,0.48,1.7,0.2,2.13-0.64l12.59-24.37c0.62-1.21,2.01-1.63,3.1-0.93
                    c0.52,0.34,0.9,0.88,1.06,1.54c0.16,0.65,0.08,1.34-0.23,1.92L65.18,41.66c-0.43,0.84-0.18,1.9,0.57,2.39
                    c0.75,0.48,1.7,0.2,2.13-0.64l10.1-19.56c0.3-0.59,0.79-1,1.38-1.18c0.58-0.18,1.2-0.09,1.72,0.25c0.52,0.34,0.9,0.88,1.06,1.54
                    c0.16,0.65,0.08,1.34-0.23,1.92L71.81,45.94c-0.43,0.84-0.18,1.9,0.57,2.39c0.75,0.48,1.7,0.2,2.13-0.64l8.13-15.74
                    c0.3-0.58,0.79-0.99,1.37-1.17c0.59-0.18,1.2-0.09,1.72,0.25c0.52,0.34,0.9,0.88,1.06,1.54c0.16,0.65,0.08,1.34-0.23,1.92
                    l-6.95,13.46c-0.43,0.84-0.18,1.9,0.57,2.39c0.25,0.16,0.51,0.23,0.78,0.23c0.54,0,1.07-0.31,1.35-0.87l6.95-13.46
                    C90,34.84,90.19,33.22,89.81,31.66L89.81,31.66z M52.54,54.77c0,6.55-1.24,12.89-3.69,18.84c-0.09,0.23-0.14,0.48-0.14,0.73v12.17
                    H25.03l0-15.49c0-0.46-0.16-0.91-0.46-1.24L23.88,69c-3.79-4.24-7.25-8.89-10.28-13.83c-0.77-1.25-0.59-2.96,0.4-3.99
                    c1.1-1.14,2.84-1.07,3.87,0.16l4.45,5.31c0.29,0.35,0.7,0.58,1.16,0.58c0.62,0,1.19-0.41,1.43-1.05c0.09-0.24,0.13-0.49,0.13-0.74
                    V26.94c0-1.4,1.02-2.53,2.27-2.53c1.25,0,2.27,1.14,2.27,2.53v22.59c0,0.96,0.7,1.75,1.56,1.75c0.86,0,1.56-0.78,1.56-1.75V21.4
                    c0-1.4,1.02-2.53,2.27-2.53c1.25,0,2.27,1.14,2.27,2.53v28.14c0,0.96,0.7,1.75,1.56,1.75c0.86,0,1.56-0.78,1.56-1.75V26.94
                    c0-1.4,1.02-2.53,2.27-2.53s2.27,1.14,2.27,2.53v22.59c0,0.96,0.7,1.75,1.56,1.75c0.86,0,1.56-0.78,1.56-1.75V31.37
                    c0-1.4,1.02-2.53,2.27-2.53s2.27,1.14,2.27,2.53L52.54,54.77L52.54,54.77z"/>
                <path fill="#FFFFFF" d="M77.45,53.88c-0.41,0-0.81,0.19-1.1,0.51c-0.29,0.32-0.46,0.77-0.46,1.23
                    c0,0.46,0.17,0.91,0.46,1.23c0.29,0.32,0.69,0.51,1.1,0.51c0.41,0,0.81-0.19,1.1-0.51c0.29-0.33,0.46-0.78,0.46-1.23
                    c0-0.46-0.17-0.91-0.46-1.23C78.27,54.07,77.87,53.88,77.45,53.88z"/>
            </g>
        </g>
        </svg>

    )
}

export default GlovesIcon
