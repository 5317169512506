import React from "react"


const PhysicalIcon = ({name}) => {
    return (
        <svg version="1.1" id="Icons" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
	 viewBox="0 0 100 100" className={name}>
        <g>
            <path fill="#FFFFFF" d="M77.34,47.42l5.91-9.29c0.51,0.17,1.04,0.26,1.59,0.26c2.85,0,5.16-2.31,5.16-5.16s-2.31-5.16-5.16-5.16
            c-2.85,0-5.16,2.31-5.16,5.16c0,1.34,0.51,2.59,1.39,3.53l-5.97,9.38H58.45L47.69,29.4l6.87-9.45c0.59,0.23,1.23,0.37,1.89,0.37
            c2.85,0,5.16-2.31,5.16-5.16S59.3,10,56.45,10s-5.16,2.31-5.16,5.16c0,1.22,0.43,2.38,1.18,3.28l-7,9.62H33.04
            c-0.58-2.22-2.58-3.87-4.98-3.87c-2.85,0-5.16,2.31-5.16,5.16c0,1.32,0.5,2.57,1.37,3.5l-5.5,8.56c-0.74-0.29-1.52-0.45-2.32-0.45
            c-3.56,0-6.45,2.89-6.45,6.45s2.89,6.45,6.45,6.45c0.8,0,1.59-0.17,2.32-0.45l7.73,12.02l-6.87,9.45
            c-0.59-0.23-1.23-0.37-1.89-0.37c-2.85,0-5.16,2.31-5.16,5.16c0,2.85,2.31,5.16,5.16,5.16s5.16-2.31,5.16-5.16
            c0-1.22-0.43-2.38-1.18-3.28l7-9.62h16.7l9.28,14.43c-0.51,0.67-0.83,1.49-0.83,2.34c0,2.13,1.74,3.87,3.87,3.87
            c1.68,0,3.1-1.08,3.63-2.58h8.11c0.6,2.94,3.21,5.16,6.32,5.16c3.56,0,6.45-2.89,6.45-6.45c0-1.77-0.72-3.41-1.96-4.61l8.65-13.46
            L77.34,47.42z M84.84,30.65c1.42,0,2.58,1.16,2.58,2.58s-1.16,2.58-2.58,2.58c-0.5,0-0.98-0.14-1.39-0.41
            c-0.75-0.47-1.19-1.29-1.19-2.17C82.26,31.8,83.42,30.65,84.84,30.65z M56.45,12.58c1.42,0,2.58,1.16,2.58,2.58
            s-1.16,2.58-2.58,2.58c-0.55,0-1.07-0.17-1.53-0.51c-0.66-0.47-1.05-1.24-1.05-2.07C53.87,13.74,55.03,12.58,56.45,12.58z
            M33.04,30.65h12.38l10.78,16.77L45.42,64.19H28.77l-7.82-12.17c1.24-1.2,1.96-2.84,1.96-4.61c0-1.77-0.72-3.41-1.96-4.61
            l5.51-8.57c0.52,0.17,1.06,0.27,1.61,0.27C30.46,34.52,32.47,32.87,33.04,30.65L33.04,30.65z M28.06,26.77
            c1.42,0,2.58,1.16,2.58,2.58c0,1.42-1.16,2.58-2.58,2.58c-0.49,0-0.96-0.14-1.4-0.42c-0.74-0.47-1.18-1.28-1.18-2.16
            C25.48,27.93,26.64,26.77,28.06,26.77z M12.58,47.42c0-2.13,1.74-3.87,3.87-3.87c0.74,0,1.46,0.21,2.09,0.62
            c1.11,0.71,1.78,1.93,1.78,3.25s-0.67,2.54-1.79,3.25c-0.62,0.41-1.34,0.62-2.08,0.62C14.32,51.29,12.58,49.55,12.58,47.42z
            M17.74,82.26c-1.42,0-2.58-1.16-2.58-2.58c0-1.42,1.16-2.58,2.58-2.58c0.55,0,1.07,0.17,1.53,0.51c0.66,0.47,1.05,1.24,1.05,2.07
            C20.32,81.1,19.17,82.26,17.74,82.26z M57.74,84.84c-0.71,0-1.29-0.58-1.29-1.29c0-0.43,0.23-0.85,0.62-1.1
            c0.19-0.13,0.41-0.19,0.67-0.19c0.71,0,1.29,0.58,1.29,1.29S58.45,84.84,57.74,84.84z M69.49,82.26h-8.11
            c-0.53-1.5-1.95-2.58-3.63-2.58c-0.3,0-0.6,0.04-0.89,0.11l-9.19-14.3l10.78-16.77H75.1l10.78,16.77l-7.76,12.07
            c-0.74-0.29-1.52-0.45-2.32-0.45C72.69,77.1,70.09,79.32,69.49,82.26L69.49,82.26z M75.81,87.42c-2.13,0-3.87-1.74-3.87-3.87
            s1.74-3.87,3.87-3.87c0.74,0,1.46,0.21,2.09,0.62c1.11,0.71,1.78,1.93,1.78,3.25C79.68,85.68,77.94,87.42,75.81,87.42z"/>
        <path fill="#FFFFFF" d="M28.06,40.97h10.32v2.58H28.06V40.97z"/>
        <path fill="#FFFFFF" d="M40.97,40.97h5.16v2.58h-5.16V40.97z"/>
        <path fill="#FFFFFF" d="M28.06,46.13h18.06v2.58H28.06V46.13z"/>
        <path fill="#FFFFFF" d="M43.55,51.29h2.58v2.58h-2.58V51.29z"/>
        <path fill="#FFFFFF" d="M38.39,51.29h2.58v2.58h-2.58V51.29z"/>
        <path fill="#FFFFFF" d="M28.06,51.29h7.74v2.58h-7.74V51.29z"/>
        <path fill="#FFFFFF" d="M57.74,59.03h10.32v2.58H57.74V59.03z"/>
        <path fill="#FFFFFF" d="M70.65,59.03h5.16v2.58h-5.16V59.03z"/>
        <path fill="#FFFFFF" d="M57.74,64.19h18.06v2.58H57.74V64.19z"/>
        <path fill="#FFFFFF" d="M73.23,69.35h2.58v2.58h-2.58V69.35z"/>
        <path fill="#FFFFFF" d="M68.06,69.35h2.58v2.58h-2.58V69.35z"/>
        <path fill="#FFFFFF" d="M57.74,69.35h7.74v2.58h-7.74V69.35z"/>
        </g>
        </svg>

    )
}

export default PhysicalIcon
