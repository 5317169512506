import styled from 'styled-components'
import * as palette from '../styles/variables';
import { device, width } from '../styles/device';


export const FeatureStyled = styled.div`
    display: flex;
    flex-direction: column;
    max-width: ${width.xxxl};
    margin-bottom: 20px;
    align-items: center;

    @media ${device.mobile} {
        margin-bottom: 20px;
    }

    @media ${device.mobile} {
        margin-bottom: 0;
    }

    .imageWrap {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .gatsby-image-wrapper {
        cursor: pointer;
        text-align: center;

        &:hover {
            transition: background-color .3s, color .3s;
        }

        img {
            object-fit: contain !important;
        }
    }

    .text {
        text-align: center;

        .title {
            color: ${palette.white};
            margin-bottom: 10px;
            text-align: center;
            font-family: ${palette.fontFamilyMain};
            font-size: ${palette.fontSizeMdl};
            font-weight: 400;
        }

        .textWrap {
            font-size: ${palette.fontSizeSm};
            line-height: ${palette.lineHeightSm};
            margin-bottom: 0;
            color: ${palette.white};

            p {
                line-height: 1.4rem;
                margin-bottom: 0;
            }
        }
    }

`;