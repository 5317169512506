import React from "react"
import {TextWithImageSectionStyled, TextWithImageInnerStyle} from './textWithImageSectionStyled'
import TextBox from './textBox'
import ImageGroup from './imageGroup'



const TextWithImageSection = () => {
    return (
        <TextWithImageSectionStyled style={{ paddingBottom: '10rem' }}>
            <TextWithImageInnerStyle>
                <TextBox className="textWithImageSection" />
                <ImageGroup slug="factory" />
            </TextWithImageInnerStyle>
        </TextWithImageSectionStyled>
    )
}

 export default TextWithImageSection

