import React from "react"


const SafetyIcon = ({name}) => {
    return (
        <svg version="1.1" id="Icons" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
	 viewBox="0 0 100 100" className={name}>
        <g>
            <path fill="#FFFFFF" d="M89.08,18.54L50.49,5.08c-0.32-0.11-0.66-0.11-0.98,0L10.92,18.54C10.37,18.73,10,19.23,10,19.78v23.06
                c0.03,18.91,10.74,36.38,28.11,45.88l11.18,6.09c0.44,0.24,0.97,0.24,1.41,0l11.18-6.09C79.26,79.22,89.97,61.75,90,42.84V19.78
                C90,19.23,89.63,18.73,89.08,18.54z M87.18,42.84c-0.03,17.96-10.2,34.56-26.71,43.59L50,92.14l-10.48-5.71
                C23.02,77.4,12.85,60.81,12.82,42.84V20.7L50,7.74L87.18,20.7V42.84z"/>
            <path fill="#FFFFFF" d="M49.51,14.5L19.61,24.93c-0.55,0.19-0.92,0.69-0.92,1.24v17.6c0.03,15.43,8.76,29.69,22.94,37.45l7.66,4.18
                c0.44,0.24,0.98,0.24,1.41,0l7.66-4.18c14.18-7.76,22.92-22.02,22.95-37.45v-17.6c0-0.55-0.37-1.05-0.92-1.24L50.49,14.5
                C50.17,14.39,49.83,14.39,49.51,14.5z M78.5,27.1v16.68c-0.03,14.49-8.23,27.87-21.54,35.16L50,82.72l-6.96-3.79
                C29.73,71.65,21.53,58.26,21.5,43.77V27.1L50,17.16L78.5,27.1z"/>
            <path fill="#FFFFFF" d="M44.36,33.08v10.1H33.62c-0.78,0-1.41,0.59-1.41,1.32v7.97c0,0.73,0.63,1.32,1.41,1.32h10.74v10.1
                c0,0.73,0.63,1.32,1.41,1.32h8.47c0.78,0,1.41-0.59,1.41-1.32v-10.1h10.74c0.78,0,1.41-0.59,1.41-1.32V44.5
                c0-0.73-0.63-1.32-1.41-1.32H55.64v-10.1c0-0.73-0.63-1.32-1.41-1.32h-8.47C44.99,31.75,44.36,32.35,44.36,33.08z M47.17,44.5
                v-10.1h5.65v10.1c0,0.73,0.63,1.32,1.41,1.32c0,0,0,0,0,0h10.73v5.32H54.24c-0.78,0-1.41,0.59-1.41,1.32c0,0,0,0,0,0v10.1h-5.65
                v-10.1c0-0.73-0.63-1.32-1.41-1.32H35.03v-5.32h10.74C46.54,45.83,47.17,45.23,47.17,44.5z"/>
        </g>
        </svg>
    )
}

export default SafetyIcon
