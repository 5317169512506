import React, {useEffect, useState} from "react"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from '../components/hero'
import { FaAngleDoubleRight } from 'react-icons/fa'
import FeatureProductHome from '../components/featureProductHome'
import Certifications from '../components/certifications'
import ProductSection from '../components/ProductSection'
import TextWithImageSection from '../components/textWithImageSection'
import ModalPopup from '../components/modalPopup'
import LogoAnimate from '../components/logoAnimate'



const IndexPage = () => {
  useEffect(() => {
    // GET LENGTH OF SVG ANIMATION
    // const logo = document.querySelectorAll('#logo_animate path')
    // const logoPolygon = document.querySelectorAll('#logo_animate polygon')
    // for(let i = 0; i < logo.length; i++) {
    //   console.log('LOGO I', logo[i].getTotalLength())
    // }
    // for(let i = 0; i < logoPolygon.length; i++) {
    //   console.log('LOGO P', logoPolygon[i].getTotalLength())
    // }


    const body = document.querySelectorAll('body')
    const overLayAnimate = document.querySelectorAll('#logoAnimateWrapper')
    let logoStorage = localStorage.getItem("overlayShow");

    if(!logoStorage) {
      body[0].classList.add('overlayOpen')
    } else {
      body[0].classList.remove('overlayOpen')
    }

    if(logoStorage) {
      overLayAnimate[0].classList.add('hide')
    }

    setTimeout(() => {
      overLayAnimate[0].style.display = 'none'
      if(!logoStorage) {
        localStorage.setItem("overlayShow", true)
        body[0].classList.remove('overlayOpen')
      }
    }, 3000);

      return () => {
      };
  });

  const data = useStaticQuery(graphql`
  query IndexPageQuery {
    allContentfulMetaDescription(filter: {position: {eq: "Home"}}) {
      edges {
        node {
          text
        }
      }
    }
  }
`)
  if(!data.allContentfulMetaDescription.edges) {
    return <div>Loading...</div>
  }
  const descriptionMeta = data.allContentfulMetaDescription.edges[0].node.text ? data.allContentfulMetaDescription.edges[0].node.text : "Top Nitrile and Latex Gloves Manufacturer in Asia | Savie Glove by Savie Group"

  return (
    <Layout hideContact={false} backgroundColor={false} showMarketingPopup={true} modalAlert={true}>
      <SEO title="Savie Glove Home" description={descriptionMeta} />
      <Hero position="Home"
      buttonText="Contact us"
      classButton="heroLanding"
      iconButton={<FaAngleDoubleRight />}
      className="homeHero"
      isSlider={true}>
      </Hero>
      <FeatureProductHome />
      <ProductSection showSize={false} flipImage={true} />
      <TextWithImageSection />
      {/* <Certifications /> */}
      <div id="logoAnimateWrapper">
          <LogoAnimate />
      </div>
    </Layout>
  )

}
 export default IndexPage

