import React from "react"


const LikeIcon = ({name}) => {
    return (
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
	        viewBox="0 0 100 100" className={name}>
            <g>
            <path fill="#FFFFFF" d="M82.7,49.6h-16c-0.6,0-1.2-0.5-1.2-1.1s0.5-1.1,1.2-1.1h16c2.8,0,5-2.2,5-4.9s-2.2-4.9-5-4.9H60
                c-0.6,0-1.2-0.5-1.2-1.1c0-0.1,0-0.2,0-0.3s3.1-13.6-1.9-20.2c-1.7-2.2-4.4-3.5-7.2-3.5c-0.5,0.1-2.3,0.9-2.1,4.7v5.7
                C47.7,23,45.6,44,27,48.2c-0.6,0.1-1.2-0.3-1.4-0.9c-0.1-0.6,0.2-1.2,0.8-1.3c16.9-3.8,18.9-23.1,18.9-23.3v-5.5
                c-0.3-4.9,2.6-6.8,4.1-7.1c0.1,0,0.2,0,0.3,0c3.6,0,6.9,1.7,9.1,4.4c4.7,6.2,3.3,16.9,2.6,20.7h21.3c4,0,7.3,3.2,7.3,7.2
                S86.7,49.6,82.7,49.6L82.7,49.6z"/>
            <path fill="#FFFFFF" d="M82.7,61.8H68.6c-0.6,0-1.2-0.5-1.2-1.1s0.5-1.1,1.2-1.1h14.1c2.8,0,5-2.2,5-4.9s-2.2-4.9-5-4.9h-16
                c-0.6,0-1.2-0.5-1.2-1.1s0.5-1.1,1.2-1.1h16c4,0,7.3,3.2,7.3,7.2S86.7,61.8,82.7,61.8z"/>
            <path fill="#FFFFFF" d="M80.2,73.9h-9.4c-0.6,0-1.2-0.5-1.2-1.1s0.5-1.1,1.2-1.1h9.4c2.8,0,5-2.2,5-4.9s-2.2-4.9-5-4.9H68.6
                c-0.6,0-1.2-0.5-1.2-1.1s0.5-1.1,1.2-1.1h11.6c4,0,7.3,3.2,7.3,7.2S84.3,73.9,80.2,73.9L80.2,73.9z"/>
            <path fill="#FFFFFF" d="M74.9,86.1H26.7c-0.6,0-1.2-0.5-1.2-1.1s0.5-1.1,1.2-1.1h48.1c2.8,0,5-2.2,5-4.9c0-2.7-2.2-4.9-5-4.9h-4
                c-0.6,0-1.2-0.5-1.2-1.1s0.5-1.1,1.2-1.1h4c4,0,7.3,3.2,7.3,7.2C82.1,82.9,78.9,86.1,74.9,86.1z"/>
            <path fill="#FFFFFF" d="M24.6,90H13.3c-1.8,0-3.3-1.5-3.3-3.3V44.2c0-1.8,1.5-3.3,3.3-3.3h11.2c1.8,0,3.3,1.5,3.3,3.3v42.5
                C27.9,88.5,26.4,90,24.6,90z M13.3,43.2c-0.6,0-1,0.5-1,1v42.5c0,0.6,0.5,1,1,1h11.2c0.6,0,1-0.5,1-1V44.2c0-0.6-0.5-1-1-1H13.3z"
		    />
        </g>
        </svg>
    )
}

export default LikeIcon
