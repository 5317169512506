import React from 'react'
import { useStaticQuery, graphql } from "gatsby"
import FeatureBlock from './featureBlock'



const FeatureProduct = () => {
    const data = useStaticQuery(graphql`
    query FeatureProductQuery {
      allContentfulFeatureProduct(sort: {fields: orderNumber}) {
        edges {
          node {
            slug
            title
            text
                {
                text
                }
            id
            orderNumber
          }
        }
      }
    }
    `)

    if(!data.allContentfulFeatureProduct.edges) {
        return <div>Loading...</div>
      } else {
        return data.allContentfulFeatureProduct.edges.map((edge) => {
          if(edge.node) {
              return <FeatureBlock
                      key={edge.node.id}
                      node={edge.node}
                    />
            }
            return true
        })
      }
}

export default FeatureProduct