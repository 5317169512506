import React from 'react'
import {FeatureStyled} from './featureStyled'
import Markdown from 'react-markdown'
import ProteinIcon from './icons/proteinIcon'
import DimensionIcon from './icons/dimensionIcon'
import GlovesIcon from './icons/glovesIcon'
import LikeIcon from './icons/likeIcon'
import PhysicalIcon from './icons/physicalIcon'
import SafetyIcon from './icons/safetyIcon'


const FeatureBlock = ({node}) => {
    const renderIcon = () => {
        switch(node.slug && node.slug.trim()) {
            case 'protein':
                return <ProteinIcon name={`icon feature-${node.orderNumber}`}/>
            case 'dimensions':
                return <DimensionIcon name={`icon feature-${node.orderNumber}`}/>
            case 'powder':
                return <LikeIcon name={`icon feature-${node.orderNumber}`}/>
            case 'biological':
                return <SafetyIcon name={`icon feature-${node.orderNumber}`}/>
            case 'freedom':
                return <GlovesIcon name={`icon feature-${node.orderNumber}`}/>
            case 'physical':
                return <PhysicalIcon name={`icon feature-${node.orderNumber}`}/>
            default:
                return '';
        }
    }
    if(node.orderNumber && node.orderNumber < 0) {
        return <div></div> 
    } else {
        return (
            <FeatureStyled className={`featureCard card-${node.orderNumber}`}>
                <div className={`imageWrap ${node.orderNumber}`}>
                {node.slug && renderIcon()}
                </div>
                <div className="text">
                    {node.title && <h3 className="title">
                        {node.title}
                    </h3>}
                    {node.text && <div className="textWrap">
                        <Markdown source={node.text.text} />
                    </div>}
                </div>
            </FeatureStyled>
        )
    }          
}
    
export default FeatureBlock