import React from "react"


const ProteinIcon = ({name}) => {
    return (
        <svg version="1.1" id="Icons" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
	        viewBox="0 0 100 100" className={name}>
        <g>
            <path d="M88.64,27.42c-0.33-1.71-0.88-3.41-1.68-5.2c-1.26-2.82-2.57-5.66-3.85-8.4l-0.25-0.53c-0.28-0.6-0.65-0.87-1.25-0.89
                c-0.01,0-0.03,0-0.04,0c-0.66,0-1.3,0.71-1.38,1.55c-0.05,0.55,0.13,1.04,0.45,1.7c0.26,0.52,0.51,1.05,0.76,1.57l0.71,1.5
                l-1.27,1.11l-0.95-0.51l-5.16-3.14c-2.14-1.3-4.36-2.65-6.63-3.7c-1.33-0.61-2.91-1.25-4.55-1.34c-1.53-0.08-2.75,0.43-3.75,1.53
                c-2.17,2.41-4.51,5.05-7.36,8.32c-1,1.15-1.5,2.6-1.49,4.3c0.01,1.04,0.18,2.12,0.53,3.41c0.49,1.8,1.21,3.72,2.27,6.02
                c1.03,2.23,2.11,4.53,3.07,6.56l1.33,2.82c0.04,0.09,0.08,0.18,0.13,0.3l1.29,2.95l-2.83-1.35c-0.1-0.05-0.17-0.08-0.23-0.12
                l-0.73-0.45c-0.47-0.29-0.94-0.58-1.41-0.86l-2.23-1.32c-2.03-1.21-4.14-2.45-6.23-3.63c-2-1.12-3.71-1.73-5.38-1.93
                c-1.69-0.2-3.03,0.28-4.09,1.46c-2.37,2.61-4.68,5.24-7.42,8.38c-0.91,1.04-1.4,2.33-1.47,3.81c-0.04,1.05,0.08,2.15,0.38,3.38
                c0.46,1.87,1.16,3.81,2.22,6.11c1.07,2.33,2.16,4.64,3.21,6.87l3.02,6.46l-3.08-1.65c-0.09-0.05-0.15-0.08-0.2-0.11l-5.18-3.16
                c-2.06-1.25-4.18-2.54-6.34-3.57c-1.44-0.69-3.01-1.34-4.68-1.47c-1.34-0.11-2.46,0.24-3.42,1.05c-1.52,1.29-2.28,2.88-2.34,4.87
                c-0.03,1,0.11,2.06,0.42,3.32c0.45,1.79,1.12,3.63,2.18,5.97c1.14,2.5,2.35,5.08,3.41,7.31c0.28,0.6,0.68,0.88,1.25,0.88
                c0.61-0.01,0.97-0.44,1.13-0.69c0.36-0.59,0.4-1.36,0.09-2.03c-0.22-0.47-0.44-0.94-0.67-1.41l-0.45-0.95
                c-0.06-0.12-0.11-0.25-0.18-0.39l-1.52-3.41l5.53,3.29c1.51,0.92,3.06,1.87,4.6,2.79c1.93,1.16,4,2.36,6.16,3.21
                c1.22,0.48,2.67,0.96,4.14,0.82c0.73-0.07,1.38-0.29,2-0.68c0.67-0.43,1.27-1.11,1.8-1.71c1.18-1.34,2.35-2.67,3.53-4.01l2.89-3.28
                c0.7-0.79,1.15-1.64,1.4-2.59c0.31-1.21,0.3-2.55-0.05-4.22c-0.37-1.77-0.99-3.6-1.89-5.61c-1.05-2.32-2.14-4.67-3.2-6.93
                l-3.68-7.81l3.91,2.39c0.5,0.31,0.98,0.6,1.46,0.88l2.24,1.34c1.96,1.17,3.98,2.38,5.99,3.52c1.54,0.87,3.6,1.91,5.84,2.17
                c0.92,0.1,1.72,0.01,2.46-0.29c0.76-0.31,1.45-0.87,2.31-1.85c1.75-2,3.56-4.04,5.15-5.85l1.44-1.63c1.05-1.19,1.59-2.53,1.64-4.08
                c0.04-1.03-0.09-2.1-0.38-3.29c-0.46-1.87-1.16-3.82-2.23-6.14c-1.07-2.33-2.18-4.69-3.26-6.97l-1.56-3.34l-1.42-3.26l3.75,2.28
                c0.95,0.56,1.9,1.13,2.85,1.7l0.04,0.02c2.22,1.33,4.52,2.71,6.8,4.01c2.06,1.18,3.82,1.86,5.53,2.12c1.72,0.27,3.11-0.16,4.29-1.3
                c0.95-0.93,1.55-1.97,1.82-3.2C88.95,30.12,88.93,28.91,88.64,27.42z M38.44,84.51c-0.49,0.5-1.17,0.75-2.1,0.75l-0.13,0
                c-0.9-0.04-1.89-0.43-2.74-0.81c-2.13-0.93-4.17-2.16-5.97-3.24c-1.44-0.87-2.88-1.73-4.31-2.6c-1.88-1.14-3.75-2.27-5.63-3.4
                c-0.46-0.27-0.94-0.53-1.4-0.77L16.1,74.4c-0.3-0.16-0.59-0.31-0.89-0.48c-0.19-0.1-0.65-0.41-0.76-0.98
                c-0.03-0.18-0.07-0.36-0.11-0.53c-0.12-0.57-0.25-1.16-0.29-1.79c-0.06-1.11,0.36-1.77,0.72-2.13c0.35-0.35,0.99-0.76,2.01-0.68
                c1.12,0.07,2.13,0.46,3.05,0.87c2.07,0.92,4.03,2.1,5.93,3.24l4.29,2.58c2.05,1.24,4.1,2.47,6.16,3.7c0.3,0.18,0.62,0.35,0.93,0.51
                l0.08,0.04c0.24,0.12,0.47,0.25,0.71,0.38c0.24,0.13,0.67,0.44,0.78,1l0.1,0.5c0.12,0.56,0.25,1.14,0.28,1.77
                C39.14,83.3,38.92,84.01,38.44,84.51z M40.05,59.14l0.07,0.14c0.47,0.98,0.94,1.96,1.4,2.95l0.58,1.23
                c1.03,2.18,2.1,4.43,2.99,6.78c0.38,1,0.79,2.17,0.97,3.42c0.07,0.53,0.08,1.03,0.02,1.47c-0.07,0.52-0.28,1.03-0.58,1.4
                c-0.52,0.63-1.06,1.23-1.58,1.81l-0.71,0.79l-1.35,0.78l-0.75-2.03c-0.19-0.54-0.38-1.09-0.59-1.61c-0.89-2.32-1.97-4.59-3.01-6.8
                l-1.45-3.07c-1.1-2.34-2.24-4.75-3.36-7.2c-0.69-1.5-1.46-3.3-1.96-5.29c-0.2-0.81-0.39-1.68-0.22-2.64
                c0.21-1.15,1.01-1.92,2.11-2.03c1-0.1,1.9,0.18,2.72,0.5c0.51,0.19,0.75,0.67,0.83,0.86C37.35,53.5,38.72,56.37,40.05,59.14z
                M62.51,56.35c-0.12,1.41-1.02,2.32-2.36,2.37c-0.06,0-0.12,0-0.17,0c-0.88,0-1.66-0.25-2.38-0.53c-1.9-0.73-3.69-1.75-5.35-2.74
                c-1.67-1-3.33-2.01-5-3.02c-1.87-1.13-3.75-2.27-5.63-3.39c-1.98-1.18-3.54-1.98-5.06-2.59l-1.76-0.75l1.33-1.44
                c0.12-0.13,0.24-0.26,0.35-0.39l0.42-0.47c0.33-0.36,0.66-0.73,0.97-1.12c0.64-0.8,1.51-1.14,2.58-1.01
                c1.15,0.14,2.19,0.57,3.14,1.01c2.14,1,4.18,2.23,6.15,3.43l2.26,1.36c2.55,1.53,5.09,3.07,7.65,4.59
                c0.32,0.19,0.65,0.36,0.97,0.53l0.04,0.02c0.23,0.12,0.46,0.24,0.68,0.36c0.21,0.12,0.6,0.39,0.74,0.87
                C62.35,54.34,62.6,55.29,62.51,56.35z M63.85,33.44c1.57,3.38,2.89,6.23,4.19,9.13c0.73,1.63,1.17,3.03,1.41,4.42
                c0.06,0.38,0.09,0.78,0.09,1.24c0,0.7-0.24,1.33-0.67,1.83l-2.03,2.31c-0.05,0.05-0.1,0.1-0.16,0.16l-1.31,1.21l-0.6-1.64
                c-0.54-1.77-1.23-3.53-2.24-5.73c-0.91-1.97-1.85-3.95-2.76-5.87l-1.72-3.63c-1.09-2.29-2.21-4.67-3.15-7.13
                c-0.39-1.03-0.79-2.14-0.95-3.36c-0.07-0.56-0.08-1.08-0.03-1.57c0.08-0.67,0.4-1.26,0.91-1.65c0.52-0.4,1.2-0.56,1.92-0.46
                c0.56,0.08,1.08,0.23,1.58,0.37l0.23,0.06c0.44,0.12,0.83,0.48,1.01,0.92c0.95,2.37,2.06,4.72,3.14,6.98
                C63.1,31.84,63.48,32.64,63.85,33.44z M85.19,31.55c-0.31,0.3-0.87,0.65-1.79,0.65c-0.12,0-0.25-0.01-0.38-0.02
                c-1.3-0.13-2.45-0.64-3.6-1.2c-2.08-1.01-4.09-2.23-6.03-3.41l-2.92-1.77c-2.12-1.28-4.52-2.73-6.84-4.07
                c-0.81-0.47-1.66-0.86-2.55-1.28l-0.03-0.02c-0.4-0.19-0.8-0.37-1.19-0.56l-1.57-0.72l2.72-3.04c0.07-0.07,0.14-0.14,0.19-0.22
                c0.66-0.93,1.63-1.3,2.88-1.11c1.35,0.21,2.57,0.79,3.6,1.3c2.08,1.04,4.09,2.26,6.04,3.45l1.23,0.75
                c2.69,1.63,5.44,3.27,8.15,4.89c0.28,0.16,0.57,0.31,0.85,0.46c0.28,0.15,0.55,0.29,0.81,0.44c0.4,0.23,0.72,0.66,0.81,1.1
                l0.08,0.37c0.12,0.55,0.25,1.13,0.29,1.75C85.99,30.26,85.74,31.03,85.19,31.55z"/>
        </g>
        </svg>

    )
}

export default ProteinIcon
